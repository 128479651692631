import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import { capitalize } from "lodash";

export default function RegisterCavalierPage() {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    form = { ...params, ...form };
    navigate("/register/cavalier/profil", { state: form });
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/register"} replace />;
  return (
    <LogoLayout onSubmit={onSubmit} progress={30}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Mon profil</h2>
      <div>
        <TextInput name="last_name" required="Nom invalide" placeHolder="Nom" label="Nom" format={capitalize} />
        <TextInput
          name="first_name"
          required="Prénom invalide"
          placeHolder="Prénom"
          label="Prénom"
          format={capitalize}
        />
        <DateTimeInput
          max={new Date()}
          name="birth_date"
          required="Date de naissance invalide"
          label="Date de naissance"
        />
      </div>
    </LogoLayout>
  );
}

import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { formatPhone, phonereg, showToast, sirenreg } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import axiosInstance from "../../../config/axios";
import SelectInput from "../../../components/form/inputs/select-input/select-input";

export default function RegisterStructurePage() {
  const redirect = window.history.state.idx == 0;
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.state;
  async function onSubmit(form: any) {
    try {
      form = { ...params, structure: form };
      const { data } = await axiosInstance.post("users/register/complete", form);
      navigate("/register/subscription", { state: { form, plans: data.plans } });
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  if (redirect) return <Navigate to={"/register"} replace />;
  const types = [
    { label: "Ecurie", value: "Ecurie" },
    { label: "Centre équestre", value: "Centre équestre" },
    { label: "Ferme équestre", value: "Ferme équestre" },
    { label: "Haras", value: "Haras" },
    { label: "Elevage", value: "Elevage" },
  ];
  return (
    <LogoLayout onSubmit={onSubmit} progress={50}>
      <h1>Inscription</h1>
      <p className="subtitle">de votre structure</p>
      <h2 className="title">La Structure</h2>
      <div>
      <TextInput  
          name="name"
          placeHolder="Nom structure"
          label="Nom de la structure"
        />
      <SelectInput
          placeHolder="Ecurie, Elevage..."
          options={types}
          name="type"
          label="Type de structure"
          required="Il faux sélectionner type structure"
        />
        <TextInput
          validate={(val: any) => (!sirenreg.test(val) ? "SIRET invalide" : true)}
          maxLength={14}
          name="siret"
          placeHolder="SIRET"
          label="SIRET"
        />
        <PlacesInput required placeHolder="Adresse" label="Adresse postale" name="address" />
        <TextInput
          validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
          name="phone"
          placeHolder="06.00.00.00.00"
          format={formatPhone}
          maxLength={14}
          label="Téléphone de la structure"
        />
        
      </div>
    </LogoLayout>
  );
}

import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useNavigate } from "react-router-dom";
import { emailReg, showToast } from "../../utils/utils";
import axiosInstance from "../../config/axios";
export default function PasswordResetPage() {
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    try {
      await axiosInstance.post("/users/password/forget", form);
      navigate("/password/code", { state: form });
    } catch (e: any) {
      if (e.response?.status == 404) showToast("L'email fourni n'est pas associé à un compte utilisateur");
      else showToast("Une erreur est survenue");
    }
  }
  return (
    <LogoLayout submitTitle="Envoyer" onSubmit={onSubmit}>
      <h2 className="title">{"Mot de passe oublié"}</h2>
      <p className="currentR">
        Veuillez renseigner l’adresse e-mail de votre compte. Nous vous enverrons un e-mail pour réinitialiser votre mot
        de passe.
      </p>
      <div>
        <TextInput
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
          name="email"
          autocomplete="off"
          type="email"
          placeHolder="Votre email"
          label="Adresse e-mail"
        />
      </div>
    </LogoLayout>
  );
}

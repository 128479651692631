import React, { useState } from "react";
import "./button.css";
export default function Button(props: {
  disabled?: boolean;
  type?: "submit" | "reset";
  onClick?: any;
  icon?:string;
  title?: string;
  className?: string;
}) {
  const [isloading, setisloading] = useState(false);
  async function onClick() {
    if (isloading) return;
    setisloading(true);
    if (props.onClick) await props.onClick();
    setisloading(false);
  }
  return (
    <button
      onClick={onClick}
      disabled={props.disabled || isloading || false}
      type={props.type || "submit"}
      className={`button ${props.className || "btn-primary"}`}
    >
      {isloading && (
        <div>
          <span
            style={{ position: "relative", left: -10 }}
            className="spinner-grow spinner-grow-sm"
            role="status"
          ></span>
        </div>
      )}
      {
        props.icon ?<img style={{marginRight:10}} src={props.icon} alt=""/>:null
      }
      {props.title && <span>{props.title}</span>}
    </button>
  );
}

import React from "react";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import TextInput from "../../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../../context/app.context";
import Button from "../../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { emailReg, formatPhone, phonereg } from "../../../../utils/utils";
import PlacesInput from "../../../../components/form/inputs/places-input/places-inputs";

export default function AccountPage() {
  const { appInfos, setAppInfos } = useAppContext();
  const user = appInfos.user;
  const methods = useForm({ mode: "onBlur" });
  console.log(methods.formState.isDirty)
  const disabled = !methods.formState.isDirty;
  function onLogout() {
    setAppInfos({ token: null, user: null });
  }
  function onSubmit() {}
  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="row">
            <h3>Votre compte</h3>
            <div className="col-md-4">
              <TextInput
                name="email"
                autocomplete="off"
                type="email"
                value={user.email}
                label="E-mail"
                validate={(val: any) =>
                  !emailReg.test(val) ? "Adresse e-mail invalide" : true
                }
              />
            </div>
            <div className="col-md-4">
              <TextInput
                validate={(val: any) =>
                  !phonereg.test(val?.replace(/\D/g, ""))
                    ? "Téléphone invalide"
                    : true
                }
                name="phone"
                value={user.structure.phone}
                format={formatPhone}
                maxLength={14}
                placeHolder="06.00.00.00.00"
                label="Téléphone"
              />
            </div>
            <div className="col-md-4">
              <PlacesInput
                value={user.structure.address}
                required
                placeHolder="Adresse"
                label="Adresse"
                name="address"
              />
            </div>
          </div>
        </div>
        <div className="graycard  mt-4">
          <div className="row">
            <h3>Réinitialisation du mot de passe</h3>
            <div className="mt-2">
              <Button className="btn-tertiary" title="Envoyer une demande" />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-10"></div>
          <div className="col">
            <Button
              onClick={methods.handleSubmit(onSubmit)}
              title="Enregistrer"
            />
          </div>
        </div>
        <div className="col mt-5">
          <Button
            onClick={onLogout}
            icon="/images/logout.svg"
            className="btn-tertiary mt-3"
            title="Déconnexion"
          />
          <Button
            icon="/images/question.svg"
            className="btn-tertiary mt-3"
            title="Besoin d'aide"
          />
          <Button
            icon="/images/delete.svg"
            className="btn-tertiary mt-3"
            title="Supprimer le compte"
          />
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}

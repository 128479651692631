import React from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
export default function GestionPage() {
  return (
    <Structurelayout header={Header()}>
      <div>GestionsPage</div>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Ma gestion</h1>
    </div>
  );
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
interface extraParamsType {
  extraParams: {
    showProgress?: boolean | string;
    showErrorMsg?: boolean;
    cache?: { key?: string; expiry: number; clean?: boolean; perSession?: boolean } | number;
  };
}
interface CustomAxiosInstance extends AxiosInstance {
  get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D> & extraParamsType
  ): Promise<R>;
}
const axiosInstance: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "/api/",
});
export default axiosInstance;

import React, { useState } from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import { Navigate, useLocation } from "react-router-dom";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { showToast } from "../../utils/utils";
import axiosInstance from "../../config/axios";

export default function RegisterSubscriptionPage() {
  const location = useLocation();
  const params = location.state;
  const monthlyPlan = params?.plans?.find((p: any) => p.userRole == params?.form.role && p.period == "monthly");
  const yearlyPlan = params?.plans?.find((p: any) => p.userRole == params?.form.role && p.period == "yearly");
  const [selected, setselected] = useState("monthly");
  const [codePromo, setCodePromo] = useState("");
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/register"} replace />;
  async function onSubmit() {
   
    try {
      const { data } = await axiosInstance.post("users/subscribe", {
        period: selected,
        codePromo: codePromo,
        successUrl: window.location.origin + "/register/success"+ (params?.form?.fromMobileApp ? "?mobile=yes":""),
        cancelUrl: window.location.origin + "/register",
      });
      window.location.href = data.session.url;
      return;
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <LogoLayout onSubmit={onSubmit} progress={70}>
      <h1>Inscription</h1>
      <p className="subtitle">de votre {params.form.role}</p>
      <h2 className="title">Votre abonnement</h2>
      <div>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Lorem ipsum dolor sit amet consectetur.
        </p>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Lorem ipsum dolor sit amet consectetur.
        </p>
        <p className="legend">
          <img className="labelimg" src={"/images/check2.svg"} alt="" />
          Lorem ipsum dolor sit amet consectetur.
        </p>
        <div>
          <SubscriptionItem
            free={monthlyPlan.free_days}
            price={monthlyPlan.price}
            title="Mensuel"
            unit="par mois"
            onClick={() => setselected("monthly")}
            selected={selected == "monthly"}
          />
          <SubscriptionItem
            selected={selected == "yearly"}
            free={yearlyPlan.free_days}
            price={yearlyPlan.price}
            onClick={() => setselected("yearly")}
            title="Annuel"
            unit="par an"
          />
        </div>
        <TextInput onChange={(val: any) => setCodePromo(val)} placeHolder="Saisir un code promo" label="Code promo" />
      </div>
    </LogoLayout>
  );
}
function SubscriptionItem(props: any) {
  return (
    <div onClick={props.onClick} className={`subscription ${props.selected ? "subselected" : ""}`}>
      <img src={props.selected ? "/images/radio-active.svg" : "/images/radio-inactive.svg"} alt="" />
      <div className="row">
        <span className="currentM">{props.title}</span>
        <span className="currentR">
          {props.price}€ {props.unit}
        </span>
        {props.free ? <span className="legend">Profitez d’un essai gratuit de {props.free} jours !</span> : null}
      </div>
    </div>
  );
}

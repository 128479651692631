import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import Button from "../../components/button/button";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function RegisterSucessPage() {
  const location = useLocation();
  const params = location.state;
  const isAfterPay = !params?.role || params?.role == "Prestataire" || params?.role == "Structure";
  const [searchParams, setSearchParams] = useSearchParams();
  const fromMobileApp = params?.fromMobileApp || searchParams.get("mobile")=='yes'
  const navigate = useNavigate();
  function onLogin(){
    if(fromMobileApp){
      window.location.href="hyko://login"
    }
    else{
       navigate("/login")
    }
  }
  return (
    <LogoLayout hideBack={true}>
      <div className="successContainer">
        <img className="successimg" src={"/images/checked.svg"} alt="" />
        <h1 className="successTitle">{isAfterPay ? "Votre paiement" : "Votre inscription"}</h1>
        <span className="subtitle">{"a bien été effectué !"}</span>
        <div className="successLink">
            <Button onClick={onLogin} title="Se connecter" />
        </div>
      </div>
    </LogoLayout>
  );
}

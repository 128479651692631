import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { formatPhone, phonereg, showToast } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import moment from "moment";
import axiosInstance from "../../../config/axios";

export default function RegisterCavalierProfilPage() {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();
  const userage = moment().diff(moment(params.birth_date), "years");
  async function onSubmit(form: any) {
    form = { ...params, ...form };
    delete form.lists;
    if (userage < 18) navigate("/register/cavalier/tuteur", { state: form });
    else {
      try {
        await axiosInstance.post("users/register/complete", form);
        navigate("/register/success", { state:  form  });
      } catch (e) {
        showToast("Une erreur est survenue");
      }
    }
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/register"} replace />;
  return (
    <LogoLayout onSubmit={onSubmit} progress={userage < 18 ? 30 : 70}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Mon profil</h2>
      <div>
        <TextInput
          validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
          name="phone"
          format={formatPhone}
          maxLength={14}
          placeHolder="06.00.00.00.00"
          label="Téléphone"
        />
        <PlacesInput name="address" required={true} placeHolder="Adresse" label="Adresse, Code postal, Ville" />
        <SelectInput
          placeHolder="Galop"
          required="galop invalide"
          name="galop"
          options={params?.lists?.Galops.map((elt: any) => {
            return { label: elt, value: elt };
          })}
          label="Galop"
        />
        <SelectInput
          placeHolder="Niveau"
          required="Niveau invalide"
          name="level"
          options={params?.lists?.Levels.map((elt: any) => {
            return { label: elt, value: elt };
          })}
          label="Galop"
        />
      </div>
    </LogoLayout>
  );
}

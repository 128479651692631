import React from "react";
import "./label.css";
export default function Label(props: labelType) {
  return props.label ? (
    <label style={{ ...props.labelStyle }} htmlFor={props.for} className={`form-label ${props.labelClass || ""}`}>
      {props.label}
    </label>
  ) : null;
}
interface labelType {
  label?: string;
  for?: string;
  required?: boolean;
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  labelIcon?: string | { className: string; style?: React.CSSProperties };
}

import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { showToast } from "../../utils/utils";
import { useAppContext } from "../../context/app.context";

export default function LoginPage() {
  const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { setAppInfos } = useAppContext();
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    try {
      const { data } = await axiosInstance.post("login", form);
      if (data.user.role != "Structure")
        return showToast(
          "Votre rôle utilisateur actuel n'est pas pris en charge sur la version web de l'application. Veuillez utiliser la version mobile pour accéder à toutes les fonctionnalités associées à votre rôle"
        );
      setAppInfos({ token: data.token, user: data.user });
      navigate("/structure");
    } catch (e: any) {
      if (e?.response?.status == 422) showToast("Les informations de connexion sont incorrectes");
      else showToast("Une erreur est survenue");
    }
  }
  return (
    <LogoLayout
      renderAfterSubmit={() => (
        <div className="bottomlink">
          <span className="currentR">Pas encore de compte ?</span>
          <Link className="btn-tertiary" title="login" to="/register">
            <span>S’inscrire</span>
          </Link>
        </div>
      )}
      submitTitle="Se connecter"
      onSubmit={onSubmit}
      hideBack={true}
    >
      <h1>Bienvenue !</h1>
      <h2 className="title">Se connecter</h2>
      <div>
        <TextInput
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
          name="email"
          autocomplete="off"
          type="email"
          placeHolder="Votre email"
          label="Adresse e-mail"
        />
        <TextInput
          autocomplete="off"
          showPassword
          name="password"
          type="password"
          required="Mot de passe invalide"
          placeHolder="Votre mot de passe"
          label="Mot de passe"
        />
        <div className="loginlink">
          <span className="currentR">Mot de passe oublié ?</span>
          <Link className="btn-tertiary" title="login" to="/password/reset">
            <span>Cliquez ici</span>
          </Link>
        </div>
      </div>
    </LogoLayout>
  );
}

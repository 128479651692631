import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { passwordReg, showToast } from "../../utils/utils";
import axiosInstance from "../../config/axios";
export default function PasswordConfirmPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const passwordRules = [
    "Plus de 8 caractères",
    "Au minimum une majuscule et une minuscule",
    "Doit contenir un chiffre ou un caractère spécial",
  ];

  async function onSubmit(form: any) {
    try {
      await axiosInstance.post("users/password/reset", { ...params, ...form });
      navigate("/password/success");
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/password/reset"} replace />;
  return (
    <LogoLayout submitTitle="Envoyer" onSubmit={onSubmit}>
      <h2 className="title">{"Mot de passe oublié"}</h2>
      <p className="currentR">Veuillez renseigner le nouveau mot de passe de votre compte.</p>
      <div>
        <TextInput
          name="password"
          autocomplete="new-password"
          showPassword
          type="password"
          placeHolder="Votre mot de passe"
          label="Nouveau mot de passe"
          helper={passwordRules}
          error={passwordRules}
          validate={(val: any) => (!passwordReg.test(val) ? "mot de passe invalide" : true)}
        />
        <TextInput
          name="confirm"
          autocomplete="new-password"
          showPassword
          type="password"
          validate={(val: any, form: any) =>
            !val || val != form.password ? "Le mot de passe n'est pas identique à celui saisi" : true
          }
          placeHolder="Votre mot de passe"
          label="Confirmation du nouveau mot de passe"
        />
      </div>
    </LogoLayout>
  );
}

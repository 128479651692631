import React from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";

export default function WelcomePage() {
  return <Structurelayout header={Header()}>welcomepage</Structurelayout>;
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Bienvenue dan votre espace</h1>
      <span className="Ecurrie de ras zibbi">Ecuries de Biévres</span>
    </div>
  );
}

import React from "react";
import "./App.css";
import AppRoutes from "./routes/app.routes";
import AppContextProvider from "./context/app.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Interceptor from "./components/interceptor/interceptor";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="container-fluid root">
      <ToastContainer limit={1} bodyClassName={"error-toast"} />
      <AppContextProvider>
        <Interceptor>
          <AppRoutes />
        </Interceptor>
      </AppContextProvider>
    </div>
  );
}

export default App;

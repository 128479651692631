/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import Label from "../../label/label";
import CheckBoxInput from "../check-input/check-input";
import "./select-input.css";
import { useController, useFormContext, FieldValues, UseControllerReturn } from "react-hook-form";
const SelectInput = SelectInputController((props: SelectInputType) => {
  const [selected, setSelected]: any = useState(!props.multiple ? "" : []);
  const [options, setOptions] = useState(props.options);
  const search = useRef("");

  useEffect(() => {
    setOptions(props.options);
    if (typeof props.value == "string" && !props.multiple) {
      const item: any = props.options.find((elt: any) => elt.value == props.value);
      setSelected(item);
    }
    if (Array.isArray(props.value) && props.multiple) {
      const items: any = props.options.filter((elt: any) => (props?.value as any).find((val: any) => val == elt.value));
      setSelected(items);
    }
  }, [props.value, props.options, props.multiple]);

  function onSearch(evt: any) {
    const key = evt.key;
    if (key == "Backspace") {
      if (search.current.length >= 1) search.current = search.current.substring(0, search.current.length - 1);
    } else if (key.length == 1) search.current += key;
    setOptions(
      props.options.filter((elt: any) => {
        return !search.current || (elt.value && elt.label.indexOf(search.current) != -1);
      })
    );
  }

  function onBlur() {
    setOptions(props.options);
    props.controller?.field?.onBlur();
  }

  function onChange(item: any, evt: any) {
    if (!props.multiple) {
      props.controller?.field?.onChange(item.value || item);
      props.controller?.field?.onBlur();
      setSelected(item);
      if (props.onChange) {
        props.onChange(item);
      }
    } else {
      evt.stopPropagation();
      const found: any = selected.find((elt: any) => elt.value == item.value);
      let items = [];
      if (found) {
        items = selected.filter((elt: any) => elt.value != item.value);
        setSelected(items);
      } else {
        items = [...selected];
        items.push(item);
        setSelected(items);
      }
      if (props.onChange) {
        props.onChange(items);
        props.controller?.field?.onChange(items);
        props.controller?.field?.onBlur();
      }
    }
  }
  function onRemoveItem(item: any, evt: any) {
    evt.stopPropagation();
    const items = selected.filter((it: any) => it.value != item.value);
    props.controller?.field?.onChange(items);
    setSelected(items);
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  const success = props.controller?.fieldState?.isDirty && !error;
  return (
    <div
      style={props.containerStyle}
      className={`input-container ${props.inline ? "row" : ""} ${props.containerClass || ""}`}
    >
      <Label
        label={props.label}
        for={props.inputId}
        labelIcon={props.labelIcon}
        labelStyle={props.labelStyle}
        labelClass={props.labelClass}
      />
      <div
        className={`dropdown  form-select dropdown-input ${props.disabled ? "dropdown-input-disabled" : ""} 
       ${error ? "form-control-invalid" : ""} ${success ? "form-control-valid" : ""}
      `}
      >
        <button
          disabled={props.disabled}
          className={`btn dropdown-input-btn `}
          type="button"
          id={props.inputId || props.name || "dropdownMenuButton"}
          data-bs-toggle="dropdown"
          onBlur={onBlur}
          aria-expanded="false"
          onKeyDown={onSearch}
        >
          {!props.multiple || selected.length == 0 ? (
            <span className={`dropdown-input-title ${!selected ? "dropdown-input-placeholder" : ""}`}>
              {selected?.label || selected?.value || props.placeHolder}
            </span>
          ) : (
            <div className="dropdown-input-title">
              {selected?.map((item: any, ind: any) => (
                <span
                  style={{ marginInlineEnd: 7 }}
                  key={"selected" + ind}
                  className={`badge rounded-pill ${item.fixed ? "bg-light text-dark" : "bg-dark"} `}
                >
                  {!item.fixed ? (
                    <i
                      onClick={onRemoveItem.bind(onRemoveItem, item)}
                      style={{ marginInlineEnd: 6, zIndex: 30 }}
                      className="bi bi-x-circle "
                    ></i>
                  ) : null}
                  {item.label || item.value}
                </span>
              ))}
            </div>
          )}
          <div className="dropdown-input-icons">
            <img src={"/images/arrow-down.svg"} alt="" />
          </div>
        </button>
        <ul className={`dropdown-menu `} aria-labelledby={props.inputId || props.name || "dropdownMenuButton"}>
          {options.map((elt: any, ind: any) => (
            <li key={(props.inputId || "") + ind}>
              <button
                className={`dropdown-item ${props.optionClass || ""} ${elt.disabled ? "disabled" : ""} ${
                  !props.multiple && elt.value == selected?.value ? "active" : ""
                } `}
                disabled={
                  elt.disabled ||
                  elt.fixed ||
                  (props.multiple &&
                    props.maxSelected == selected.length &&
                    !selected.find((item: any) => item.value == elt.value))
                }
                style={{ overflow: "hidden", ...props.optionStyle }}
                onClick={onChange.bind(onChange, elt)}
                type="button"
              >
                {props.multiple ? (
                  <CheckBoxInput
                    disabled={
                      elt.disabled ||
                      elt.fixed ||
                      (props.maxSelected == selected.length && !selected.find((item: any) => item.value == elt.value))
                    }
                    value={selected.find((item: any) => item.value == elt.value)}
                    inputStyle={{ marginTop: 10 }}
                  />
                ) : null}
                {props?.itemRender ? props.itemRender(elt) : elt.label || elt.value}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {error && (
        <div className={`form-error-msg`}>
          <img
            style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
            src={"/images/error.svg"}
            alt=""
          />
          {errorMSg}
        </div>
      )}
    </div>
  );
});
export default SelectInput;
function SelectInputController(Input: any) {
  return function (props: SelectInputType) {
    return SelectControl(Input, props);
  };
}
export function SelectControl(Input: any, p: SelectInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    controller = useController({
      name: p.name || "",
      rules: {
        validate: (val: any) => {
          let res: any = true;
          if (p.required) {
            if (p.multiple) res = val && val.length > 0 ? true : p.required;
            else res = val != undefined && val != "" ? true : p.required;
          }
          if (p.multiple && p.minSelected && val?.length < p.minSelected) res = false;
          return res;
        },
      },
      defaultValue:
        !p.multiple && p.value && !Array.isArray(p.value)
          ? p.value
          : Array.isArray(p.value) && p.multiple
          ? p?.value?.filter((elt: any) => (p.options as any).find((elt1: any) => elt1.value == elt || elt1 == elt))
          : !p.multiple
          ? ""
          : [],
    });
  return <Input {...p} controller={controller} />;
}
export interface SelectInputType {
  label?: string; //done
  labelIcon?: string | { className: string; style?: React.CSSProperties };
  labelClass?: string; //done
  placeHolder?: string;
  labelStyle?: React.CSSProperties; //done
  value?: string | string[]; //done
  inputClass?: string; //dne
  inputStyle?: React.CSSProperties; //done
  inputId?: string; //done
  errorMsg?: boolean;
  listonly?: boolean;
  form?: string;
  controller?: UseControllerReturn<FieldValues, string>;
  errorMode?: "tooltip" | "feedback";
  name?: string;
  allowClear?: boolean;
  minSelected?: number;
  maxSelected?: number;
  multiple?: boolean;
  disabled?: boolean; //done
  required?: boolean | string;
  itemRender?: any;
  inline?: boolean;
  options: Array<typeOption>;
  containerClass?: string; //done
  optionClass?: string;
  containerStyle?: React.CSSProperties; //done
  optionStyle?: React.CSSProperties;
  optionGroupStyle?: React.CSSProperties;
  optionGroupClass?: string;
  onChange?: Function;
}
interface typeOption {
  value: string | number;
  label?: string;
  disabled?: boolean;
  fixed?: boolean;
}
